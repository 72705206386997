import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "react-apexcharts";
import Moment from "react-moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import moment from "moment";

const ClosingBalance = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, setLoading, dataPaginating } = useContext(SettingsContext);
  // States hook here
  const [amountChart, setAmountChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: _t(t("Amount")),
        data: [],
      },
    ],
  });

  //all data
  const [reportData, setReportData] = useState(null);
  const [list, setList] = useState([]);
  const [openingBal, setOpeningBal] = useState(null);

  // paidMoney
  const [paidMoney, setPaidMoney] = useState(0);
  //return
  const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);

  //settle order
  const [checkOrderDetails, setCheckOrderDetails] = useState({
    item: null,
    settle: false,
    uploading: false,
    payment_type: null,
    payment_amount: null,
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);
  const [closedDate, setClosedDate] = useState(null);
  const [reported, setReported] = useState(false);

  //useEffect == componentDidMount()
  useEffect(() => {
    getGenrateList();
    generateList();
  }, []);

  //get GroupWise reports filter
  const getGenrateList = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/getClosingBalance";

    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        const today = moment();
        const closeddate = moment(res.data.data[0].closing_date, "YYYY-MM-DD");
        const compare = today.isSame(closeddate, "d");
        if (compare) {
          setReported(true);
          setClosedDate(res.data.data[0].closing_date);
        } else {
          let nextDay = closeddate.add(1, "days").format("YYYY-MM-DD");
          setClosedDate(nextDay);
        }
        console.log(today, closeddate);
        console.log(compare);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const generateList = async () => {
    const url = BASE_URL + "/settings/closingBalanceList";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        console.log(res.data.data, "sssssss");
        setList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const genrateClosing = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/generateClosingBalanace";
    return axios
      .post(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        console.log(res.data.keyword);
        if (res.data.keyword == "success") {
          getGenrateList();
          generateList();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <>
      <Helmet>
        <title>{_t(t("Closing Balance"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Closing Balance"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {closedDate && (
                          <div
                            style={{
                              height: 150,
                              paddingLeft: 100,
                              paddingTop: 50,
                              border: "1px solid grey",
                            }}
                          >
                            <div className="row gx-2 mt-2">
                              <div className="col-12 col-md-6 d-md-block">
                                {reported ? (
                                  <span>
                                    Generated closing balance for {closedDate}
                                  </span>
                                ) : (
                                  <span>
                                    Generate closing balance for {closedDate}
                                  </span>
                                )}
                              </div>

                              <div className="col-5 col-md-4 t-mb-15 mb-md-0 d-none d-md-block text-right">
                                <button
                                  disabled={reported}
                                  className="btn btn-block btn-primary text-uppercase sm-text py-2"
                                  onClick={genrateClosing}
                                >
                                  {reported
                                    ? _t(t("Generated"))
                                    : _t(t("Generate Report"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  <div
                    style={{ marginTop: 50 }}
                    className="col-12 t-mb-15 mb-md-0 table-responsive"
                  >
                    <table className="table table-bordered table-hover min-table-height">
                      <thead className="align-middle">
                        <tr>
                          <th
                            scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border"
                          >
                            {_t(t("S/L"))}
                          </th>

                          <th
                            scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border"
                          >
                            {_t(t("Closing Date"))}
                          </th>

                          <th
                            scope="col"
                            className="sm-text text-capitalize align-middle text-center border-1 border"
                          >
                            {_t(t("Balance"))}
                          </th>
                        </tr>
                      </thead>
                      {list?.length > 0 && (
                        <tbody className="align-middle">
                          {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                          {list.map((item, index) => (
                            <tr key={index} className="align-middle">
                              <th
                                scope="row"
                                className="xsm-text text-capitalize align-middle text-center"
                              >
                                {index + 1}
                              </th>

                              <td className="xsm-text text-capitalize align-middle text-center">
                                {item.closing_date}
                              </td>

                              <td className="xsm-text text-capitalize align-middle text-center">
                                {item.balance}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default ClosingBalance;
